<template>
  <div class="row">
    <div class="col-12 col-lg-6 c g">
        <div class="card">
            <div class="card-header">
                <h4>
                    اعدادات الارسال من الواتساب
                </h4>
            </div>
            <div class="card-body">
                <div class="g">
                <div class="form-group">
                    <input type="text" style="border: solid 2px #999; height: 60px"
                        class="form-control form-control-lg" v-model="user.settings.jwt" placeholder="JWT هنا...">
                    </div>
                    <div class="form-group">
                    <input type="text" v-model="user.settings.device_id" style="border: solid 2px #999; height: 60px"
                        class="form-control form-control-lg" placeholder="Device ID هنا...">
                    </div>
                    <div class="form-group">
                    <input type="text" v-model="user.settings.sendto" style="border: solid 2px #999; height: 60px"
                        class="form-control form-control-lg" placeholder="الرقم الذي سيصل عليه الاشعارات...">
                    </div>
                </div>
                يمكنك الحصول عليهم <a href="https://smart-whats.com" target="_blank">من هنا</a>
            </div>
        </div>
        <div class="card card-body">
            <h5>
                اعدادات اداة الحجز
            </h5>
            <hr>
            <div class="form-check">
              <label class="form-check-label">
                <input type="checkbox" class="form-check-input" v-model="user.settings.enableotp" value="yes">
                تفعيل تسجيل الدخول التلقائي
              </label>
            </div>
            <hr>
            <div class="form-group">
              <label for="">القطع المهمة (لها اولوية الحجز) [رقم القطعة مثل: 713794]</label>
              <textarea class="form-control" v-model="user.settings.top" placeholder="اكتبهم تحت بعض..." rows="3"></textarea>
            </div>
        </div>
        <div class="col-12 text-center g">
            <button class="btn btn-success btn-lg" @click="save()">حفظ الاعدادات</button>
        </div>
        <br>
        <div class="card card-body">
            <div class="form-group">
            <input type="text" v-model="user.jwt" style="border: solid 2px #999; height: 60px"
                class="form-control form-control-lg" readonly>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
export default {
    data(){
        return {
            user: JSON.parse(localStorage.getItem("user")),
        }
    },
    created(){
        var g = this;
    },
    methods: {
        save(){
            var g = this;
            $.post(api + '/user/auth/settings', {
                jwt: g.user.jwt,
                settings: g.user.settings
            }).then(function(r){
                alert("تم الحفظ")
            })
        }
    }
}
</script>

<style>

</style>